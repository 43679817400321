import React from "react";
import DontMiss from "../../homepage/dontMiss";
import HomepageProducts from "../../homepage/homepageProducts";
import { useParams } from "react-router-dom";
import Topsection from "./topsection";
import PageHeaderSecondary from "../../molecules/PageHeaderSecondary";

function Productdetails() {
  const { id } = useParams();
  return (
    <div>
      <PageHeaderSecondary title_1="COLLECTIONS" />

      <Topsection params={id} />
      <HomepageProducts />
      <DontMiss />
    </div>
  );
}

export default Productdetails;
