// Collections.tsx
"use client";
import React, { useEffect } from "react";
import { motion } from "framer-motion";
import getCurrencyByDomain from "./data";
import DiamondShapes from "./diamondShapes";
import { useNavigate } from "react-router-dom";
import Loader from "../molecules/loader/loader";
import { useTranslation } from "react-i18next"; // Import useTranslation
import i18next from "i18next";
import searchImageGrey from "../../assets/icons/search-gray.svg"
import refreshIcon from "../../assets/icons/refresh-green.svg"

// Define the Product type
interface Product {
  _id: string;
  code: string;
  name: string;
  minPrice: number;
  maxPrice: number;
  mainImage: string;
  currencySign: string;
  specs: {
    metal: { name: string }[];
  };
}

function Collections() {
  const { t } = useTranslation(); // Get translation function
  const navigate = useNavigate();
  const [selectedShape, setSelectedShape] = React.useState<string>("");
  const [selectedPriceRange, setSelectedPriceRange] = React.useState<
    string | null
  >(null);
  const [selectedMetal, setSelectedMetal] = React.useState<string | null>("");
  const [selectedSize, setSelectedSize] = React.useState<string | null>("");
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [products, setProducts] = React.useState<Product[]>([]);
  const [size, setSize] = React.useState([]);
  const [metal, setMetal] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(true); // Loading state

  // Function to fetch products based on filters
  const fetchProducts = async () => {
    const minPrice = selectedPriceRange?.split(" - ")[0] || "";
    const maxPrice = selectedPriceRange?.split(" - ")[1] || "";

    setLoading(true); // Set loading to true when fetching data
    const response = await fetch(
      `https://api.ecorocksdenia.com/api/product/products?shape=${selectedShape}&minPrice=${minPrice}&maxPrice=${maxPrice}&sizeId=${selectedSize}&metalId=${selectedMetal}&currencyCode=${getCurrencyByDomain()}`
    );
    const data = await response.json();
    if (data?.data?.length > 0 && data?.status === 200) {
      setProducts(data?.data); // Assuming the response data is an array of products
      setLoading(false); // Set loading to false after data is fetched
    } else if(data?.data?.length === 0 && data?.status === 200) {
      setProducts([]); // Assuming the response data is an array of products
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    initialLoad();
  }, []);

  const initialLoad = async () => {
    setLoading(true); // Set loading to true while fetching sizes and metals
    const sizeResponse = await fetch(
      `https://api.ecorocksdenia.com/api/variant/sizes/sizes`
    );
    const sizeData = await sizeResponse.json();
    console.log(sizeData);
    setSize(sizeData?.data);

    const metalResponse = await fetch(
      `https://api.ecorocksdenia.com/api/variant/metal/metals`
    );
    const metalData = await metalResponse.json();
    setMetal(metalData?.data);

    setLoading(false); // Set loading to false after sizes and metals are fetched
  };

  // Effect to fetch products whenever filters change
  useEffect(() => {
    fetchProducts();
  }, [selectedShape, selectedPriceRange, selectedMetal]);

  return (
    <div className="widow-main py-[50px] md:py-[100px] max-w-full overflow-hidden">
      <div className="widow-inner">
        <div>
          <DiamondShapes
            onSelectShape={(shape: string) => setSelectedShape(shape)}
            selectedShape={selectedShape}
          />
        </div>

        {/* Filters Section */}
        <div className="grid md:grid-cols-4 col-span-1 gap-[24px] mb-[32px]">
          <select
            onChange={(e) => setSelectedSize(e.target.value)}
            value={selectedSize || ""}
            className="text-[18px] rounded-none border-b border-b-[#161616] pb-2 outline-none pl-[25px] pr-0 placeholder:text-[#A2A2A2]"
          >
            <option value="" disabled>
              {t("filters.size")}
            </option>
            {size?.map((x: any) => (
              <option key={x._id} value={x._id}>
                {x.size}
              </option>
            ))}
          </select>

          <select
            onChange={(e) => setSelectedMetal(e.target.value)}
            value={selectedMetal || ""}
            className="text-[18px] rounded-none border-b border-b-[#161616] pb-2 outline-none pl-[25px] pr-0 placeholder:text-[#A2A2A2]"
          >
            <option value="" disabled>
              {t("filters.metal")}
            </option>
            {metal?.map((x: any) => (
              <option key={x._id} value={x._id}>
                {x.name}
              </option>
            ))}
          </select>

          <div></div>

          <div className="flex md:gap-10 gap-2">
            <div className="w-full relative">
              <img
                src={searchImageGrey}
                className="w-[16] h-[16] object-contain absolute mt-1"
              />
              <input
                type="search"
                placeholder={t("filters.search")}
                className="text-[18px] w-full rounded-none border-b border-b-[#161616] pb-2 outline-none pl-[25px] pr-0 placeholder:text-[#A2A2A2]"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <button
              className="shrink-0 w-[32px] h-[32px]"
              onClick={() => {
                setSelectedShape("");
                setSelectedPriceRange(null);
                setSelectedMetal("");
                setSearchTerm("");
              }}
            >
              <img
                src={refreshIcon}
                className="w-[100%] h-[100%] object-contain"
              />
            </button>
          </div>
        </div>

        {loading && <Loader />}
        <div className="grid md:grid-cols-4 grid-cols-1 gap-[24px] gap-y-[50px] mt-[24px] max-w-full overflow-hidden">
        {products?.length > 0 &&
            products?.map((item: Product, index: number) => (
              <div
              className="flex flex-col w-full p-1 object-cover sm:w-[calc(50%-24px)] lg:w-[242px] xl:w-[282px] test cursor-pointer"
              key={item._id}
              >
                <motion.img
                  src={item.mainImage}
                  whileHover="animate"
                  whileInView="show"
                  initial="hide"
                  onClick={() => navigate(`collections/${item?._id}`)}
                  variants={{
                    animate: { scale: 1.05 },
                    hide: { opacity: 0, y: 20 },
                    show: { opacity: 1, y: 0, transition: { duration: 1 } },
                  }}
                  className=" h-full md:h-[300px] object-cover mb-[5px]"
                  />
                <span className="text_14 text-[#8A8A8A]">{item.code}</span>
                <span className="text_16 text-[#161616] font-[500]"
                onClick={() => navigate(`collections/${item?._id}`)}
                >
                  {item.name}
                </span>
                <span className="text_24 text-[#2D2D2D] font-[500] mt-[6px]">
                  {`${item.currencySign}${item.minPrice} to ${item.currencySign}${item.maxPrice}`}
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Collections;
